<template lang="pug">
div
  v-text-field(
    :value="value"
    @input="$emit('input', $event)"
    @blur="$emit('blur', $event)"
    :hide-details="hideDetails"
    :label="label"
    :error="error"
    :clearable="clearable"
    :error-messages="errorMessages"
    :data-test="dataTest"
    :disabled="disabled"
  )
    <template v-slot:append>
      v-menu(
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :left="left"
        min-width="290px"
      )
        template(v-slot:activator="{ on }")
          v-icon(v-on="on" :disabled="disabled") mdi-calendar-today
        v-date-picker(
          v-if="!timepicker"
          v-model="datepickerValue"
          scrollable
          @change="onValueChange"
          :min="minDate"
          :disabled="disabled"
        )
        v-time-picker(
          v-else
          v-model="timepickerValue"
          format="24hr"
          @change="onValueChange"
          :hideDetails="hideDetails"
          :disabled="disabled"
        )
    </template>
</template>

<script>
import { DATE_DEFAULT_REGXP, HOUR_DEFAULT_REGXP } from '@/util/const'
import { dateFormating, convertToDefaultDateFormat, convertToDefaultBackendFormat } from '../../util'
export default {
  props: {
    value: {
      required: true
    },
    label: String,
    error: Boolean,
    errorMessages: String,
    hideDetails: {
      type: Boolean,
    },
    min: String,
    dataTest: String,
    allowedMinutes: {
      type: Function,
      default: m => m % 10 === 0,
    },
    onlyFutureDate: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    timepicker: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false
  }),

  computed: {
    datepickerValue: {
      get() {
        if(DATE_DEFAULT_REGXP.test(this.value)) return dateFormating(this.value).join('-')
        return null
      },
      set(val) {
        this.$emit('input', convertToDefaultDateFormat(val))
      }
    },

    timepickerValue: {
      get() {
        if(HOUR_DEFAULT_REGXP.test(this.value)) return this.value
        return null
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    minDate() {
      if(this.onlyFutureDate) return  convertToDefaultBackendFormat(new Date(Date.now()))
      return null
    }
  },

  methods: {
    onValueChange() {
      this.menu = false;
      this.$emit('change');
    }
  }
}
</script>
